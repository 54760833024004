import React, { useState, useEffect }  from 'react';
import '../../css/ProjectList/ProjectList.css';



const ProjectList = ({projectInfo}) =>{
    const [projects, setProjects] = useState(projectInfo);



    
    

    return (
        <div className='project-container'>
        {
        projects.map((project)=>{
            return (
              <div className="img-container">
                <a
                  className="card-a-link"
                  target="_blank"
                  href={project.webUrl}
                >
                  <div className='mini-webpage-container'>
                    <img
                      src={project.imgUrl}
                      id={project.id}
                      className="mini-webpage-image"
                    />
                  </div>

                  <p className="project-card-title">{project.name}</p>
                </a>
              </div>
            );
        })
    }
        </div>
    );
}


export default ProjectList;