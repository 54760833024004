const projectsJS = [
  {
    id: 1,
    name: "Tours",
    imgUrl: "images/ProjectList/tours.png",
    webUrl: "https://stunning-khapse-58cef3.netlify.app",
    techType: "react",
  },
  {
    id: 2,
    name: "Accordian",
    imgUrl: "images/ProjectList/accordian.png",
    webUrl: "https://magical-kulfi-4622d6.netlify.app",
    techType: "react",
  },
  {
    id: 3,
    name: "Menu",
    imgUrl: "images/ProjectList/menu.png",
    webUrl: "https://lambent-halva-adc089.netlify.app",
    techType: "react",
  },
  {
    id: 4,
    name: "Color Picker",
    imgUrl: "images/ProjectList/color-picker.png",
    webUrl: "https://capable-sunflower-f45e3b.netlify.app",
    techType: "javascript",
  },
  {
    id: 5,
    name: "Counter",
    imgUrl: "images/ProjectList/counter.png",
    webUrl: "https://fastidious-brioche-49f5be.netlify.app",
    techType: "javascript",
  },
  {
    id: 6,
    name: "Reviews",
    imgUrl: "images/ProjectList/reviews.png",
    webUrl: "https://guileless-pudding-9f5b94.netlify.app",
    techType: "javascript",
  },
  {
    id: 7,
    name: "Tabs",
    imgUrl: "images/ProjectList/tabs.png",
    webUrl: "https://stalwart-swan-3030f4.netlify.app",
    techType: "react",
  },
  {
    id: 8,
    name: "Lorem Ipsum",
    imgUrl: "images/ProjectList/lorem.png",
    webUrl: "https://calm-babka-8a2a89.netlify.app",
    techType: "react",
  },
  {
    id: 9,
    name: "Color Generator",
    imgUrl: "images/ProjectList/color-generator.png",
    webUrl: "https://willowy-dusk-752e65.netlify.app",
    techType: "react",
  },
  {
    id: 10,
    name: "Grocery Cart Buddy",
    imgUrl: "images/ProjectList/grocery-bud.png",
    webUrl: "https://extraordinary-yeot-898e63.netlify.app",
    techType: "react",
  },
  {
    id: 11,
    name: "Navigation Bar",
    imgUrl: "images/ProjectList/navbar.png",
    webUrl: "https://eclectic-lollipop-f9f3b2.netlify.app/",
    techType: "react",
  },
  
];


export default projectsJS;