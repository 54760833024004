import React from 'react';
import '../../css/Header/Header.css'

const Header = () => {
    return (
      <div className="flex-container-h-Header">
        <div className="text-part">
          <p className="page-title">React &#38; Javascript Projects</p>
          <p className="description">
            Projects are the most practical way to learn any language, <span className='react-span'>React</span>and{" "}
            <span className="js-span">Javascript</span> is no exception.
            Solidify your knowledge of Javascript by creating these cool
            projects.
          </p>
          <a href="#project-part">
            <button className="page-button">See Now</button>
          </a>
        </div>
        <div className="header-image-container">
          <img
            className="header-image-container"
            src="https://www.vanillajavascriptprojects.com/_next/static/media/hero-2.cbb732ba.svg"
          ></img>
        </div>
      </div>
    );
}


export default Header;

