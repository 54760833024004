
import '../../css/App/App.css';
import Header from '../Header/Header';
import ProjectList from '../ProjectList/ProjectList';
import data from '../../data/ProjectList/js-projects-info'
function App() {

  const getProjects = (projectType)=>{
    
    const filteredList = data.filter((dataElement)=>dataElement.techType===projectType);
    console.log(filteredList);
    return filteredList.reverse();
  } 
  return (
    <div className="flex-container-v">
      <header className="header">
        <Header />
      </header>

      <section id = "project-part" className="flex-container-h">
        <div className="project-title">
          React <span className="pj-span-react">Proj</span>ects
        </div>

        <div className="react-project-menu">
          <ProjectList projectInfo={getProjects("react")} />
        </div>

        <div className="project-title">
          Javascript <span className="pj-span-js">Proj</span>ects
        </div>

        <div className="react-project-menu">
          <ProjectList projectInfo={getProjects("javascript")} />
        </div>
      </section>

      {/* This the Javaascript portion of it, just reusing component with new data */}
    </div>
  );
}
        {
          /* Add second project compoenent for js projects 
        
        <div className="project-title"></div>
        <div className="js-project-menu">

        </div>*/
        }

export default App;

/* All Projects

1. Add tab titlex 
2. Add Header component
3. Set up Header component css
4. Set up Header Media Queries
5. Set up Project - Component
6. Set up Data for projects
7. Set up Projects CSS
8. Set up Project Media Queries
9. Submit Netlify
11. Add Link to URL - > Github, Resume, LinkedIn

*/